import { useEffect } from 'react';

import loadable from '@loadable/component';
import i18n from 'i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import ModalConductor from '@/components/organisms/Modals/ModalConductor';
import Account from '@/components/pages/Account/Account';
import Accounts from '@/components/pages/Accounts/Accounts';
import Login from '@/components/pages/LoginSignup/Login/Login';
import Signup from '@/components/pages/LoginSignup/Signup/Signup';
import Welcome from '@/components/pages/LoginSignup/Signup/Welcome';
import Logout from '@/components/pages/Logout/Logout';
import NotFound from '@/components/pages/NotFound/NotFound';
import ProfileRouter from '@/components/pages/Profile/ProfileRouter';
import Unauthorized from '@/components/pages/Unauthorized/Unauthorized';
import { useAccount } from '@/hooks/useAccount';
import { useBeamer } from '@/hooks/useBeamer';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useMembers from '@/hooks/useMembers';
import { useNavigatorOnLine } from '@/hooks/useNavigatorOnline';
import { usePreferences } from '@/hooks/usePreferences';
import { useRoles } from '@/hooks/useRoles';
import { User } from '@/models/user';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';
import { InvitationRouter } from '@/modules/invitation/InvitationRouter';
import { RegisterPage } from '@/modules/invitation/pages/RegisterPage/RegisterPage';
import NotificationConductor from '@/modules/notifications/components/NotificationConductor';
import { selectUser } from '@/redux/user/selectors';
import { identifyUser } from '@/segment/segment';

import Re from './RouteElement';
import useIframeStyles from '../useIframeStyles';

const OnboardingNew = loadable(() => import('@/modules/onboarding/Router'));
const Onboarding = loadable(
  () => import('@/modules/onboarding/OldOnboarding/Router')
);

const AppLayout = () => {
  const user = useSelector(selectUser, shallowEqual) as User | null;
  const { trackUser } = useFeatureFlag();
  const { userPreferences } = usePreferences();
  const { account } = useAccount();
  const billingState = useBillingState();
  const { member } = useMembers();
  const { roles } = useRoles();
  useBeamer();
  const preventUserIdentification =
    !user?.email ||
    !userPreferences ||
    !account ||
    !billingState ||
    !roles ||
    !member;

  useNavigatorOnLine();

  useIframeStyles();

  useEffect(() => {
    if (preventUserIdentification) {
      return;
    }
    // Do not track moveo users in logrocket
    const trackInLogRocket = trackUser && !user?.email?.includes('@moveo.ai');
    identifyUser(user, userPreferences, trackInLogRocket);
  }, [preventUserIdentification, trackUser, user, userPreferences]);

  // Language
  const diffLanguage = userPreferences?.language !== i18n.language;
  useEffect(() => {
    if (userPreferences?.language) {
      i18n.changeLanguage(String(userPreferences?.language));
    }
  }, [diffLanguage, userPreferences?.language]);

  return (
    <>
      <Outlet />
      <ModalConductor />
      <NotificationConductor />
    </>
  );
};

const routes = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/*',
        element: <Re comp={Accounts} />,
      },
      {
        path: '/new-onboarding/*',
        element: <Re comp={OnboardingNew} />,
      },
      {
        path: '/onboarding/*',
        element: <Re comp={Onboarding} />,
      },
      {
        path: '/logout',
        element: <Re auth={false} comp={Logout} />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: '/invitation/register',
        element: <RegisterPage />,
      },
      {
        path: '/invitation/*',
        element: <InvitationRouter />,
      },
      {
        path: '/signup/welcome',
        element: <Re comp={Welcome} />,
      },
      {
        path: '/unauthorized',
        element: <Unauthorized />,
      },
      {
        path: '/not-found',
        element: <NotFound />,
      },
      {
        path: '/profile/*',
        element: <Re comp={ProfileRouter} />,
      },
      {
        path: '/:slug/*',
        element: <Re comp={Account} />,
      },
    ],
  },
];

// According to https://reactrouter.com/en/main/routers/create-browser-router
// This is the recommended router for all React Router web projects.
// It uses the DOM History API to update the URL and manage the history stack.
export const browserRouter = createBrowserRouter(routes);
