import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { setIframeView } from '@/redux/user/actions';

const hgs_blue = '#009EF7';
const hgs_nav_blue = '#0A3451';
const useIframeStyles = () => {
  const [URLSearchParams] = useSearchParams();
  const iframe = URLSearchParams.get('iframe') === 'true';
  const dispatch = useDispatch();
  useEffect(() => {
    if (iframe) {
      dispatch(setIframeView(true));
      document.documentElement.style.setProperty(
        '--icon-default-blue',
        hgs_blue
      );

      document.documentElement.style.setProperty(
        '--cta-default-blue',
        hgs_blue
      );
      document.documentElement.style.setProperty(
        '--surface-secondary-blue-dark',
        hgs_nav_blue
      );
    }
  }, [dispatch, iframe]);
};

export default useIframeStyles;
