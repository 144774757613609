import React from 'react';

import cn from 'classnames';

import Brain from '@/components/atoms/Icons/Brain';
import Collection from '@/components/atoms/Icons/Collection';
import Environment from '@/components/atoms/Icons/Environment';
import Home from '@/components/atoms/Icons/Home';
import Integrations from '@/components/atoms/Icons/Integrations';
import Rules from '@/components/atoms/Icons/Rules';
import { IconBroadcast } from '@/modules/broadcast/icons/Broadcast';

import NavigationItem from '../NavigationItem/NavigationItem';

import styles from './NavigationIframe.module.scss';

interface itemProps {
  title: string;
  path: string;
  icon: React.ReactNode;
}

const MainNavigationIframe = () => {
  const navigationItems: Array<itemProps> = [
    { title: 'main_navigation.home', path: '/', icon: <Home /> },
    {
      title: 'main_navigation.brains',
      path: '/brains',
      icon: <Brain />,
    },
    {
      title: 'main_navigation.collections',
      path: '/collections',
      icon: <Collection color="currentColor" size={16} />,
    },
    {
      title: 'main_navigation.broadcast_other',
      path: '/broadcasts',
      icon: <IconBroadcast />,
    },
    {
      title: 'main_navigation.integrations',
      path: '/integrations',
      icon: <Integrations color="currentColor" />,
    },
    {
      title: 'main_navigation.rules',
      path: '/rules',
      icon: <Rules color="currentColor" size={16} />,
    },
    {
      title: 'main_navigation.environments',
      path: '/environments',
      icon: <Environment size={16} />,
    },
  ];

  return (
    <nav className={cn(styles.navbar)}>
      <div className={styles.navigationList}>
        {navigationItems.map((item, index) => {
          return (
            <NavigationItem
              key={item.title}
              i18nKey={item.title}
              index={index}
              icon={item.icon}
              link={item.path}
              isIframe={true}
            />
          );
        })}
      </div>
    </nav>
  );
};

export default MainNavigationIframe;
